@import "../../variables.module.scss";

.modalBtn {
  background-color: transparent;
  box-shadow: none;
  padding: 10px 12px;
  border: none;
  height: initial;

  &.typeUpper {
    text-transform: uppercase;
  }

  &:hover {
    background-color: transparent !important;
    span {
      color: $colorPrimarlyBlue !important;
    }
  }

  &:disabled {
    span {
      color: $colorGreyFourth !important;
    }

    cursor: default;
  }

  span {
    color: $colorPrimarlyGreen;
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 1.56;
  }
}
