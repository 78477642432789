@import "../../variables.module.scss";

.wrapperLabel {
  position: relative;
}

.label {
  font-size: 0.938rem !important;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 14px;
  line-height: 1.5;
  top: calc(50% - 0.938rem * 1.5 / 2);
  transition: 0.2s ease all;
  z-index: 1;
  color: $colorGreyThird;

  &:not(.labelFloat) {
    top: 50%;
    transform: translateY(-50%);
  }

  &.hasPrefix {
    left: 48px;
  }

  &.labelFloat {
    top: 7px;
    font-size: 0.688rem !important;
    color: $colorPrimarlyGreen;

    &.fieldIsInvalid {
      color: $colorError;
    }
  }

  &.labelFloat.isFilled:not(.labelFloat) {
    color: $colorGreyThird;
  }
}
