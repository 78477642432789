.message {
  > div {
    max-width: 500px;
  }
  :global(.ant-message-custom-content) {
    span:last-child {
      font-size: 0.938rem;
    }
  }
}
