$colorPrimarlyGreen: #67d394;
$colorPrimarlyBlue: #7db2f1;

$colorSecondaryBlack: #2e2c34;
$colorSecondaryWhite: #ffffff;
$colorSecondaryYellow: #ffd667;

$colorGreyFirst: rgba(46, 44, 52, 48%);
$colorGreySecond: #85879b;
$colorGreyThird: #c3c5cf;
$colorGreyFourth: #e7e8f1;
$colorGreyFifth: #f5f7ff;

$colorError: #ff7394;

$colorLightPink: #ffeef2;
$colorLightBlue: #e8f3ff;
$colorLightGreen: #e8faf0;

$fontFamily: "Montserrat", "Segoe UI", "Roboto", sans-serif;

$headerLineHeight: 1.538461538;
$textLineHeight: 1.466666667;

:export {
  greenColor: $colorPrimarlyGreen;
  mainTextColor: $colorSecondaryBlack;
  grayColor: $colorGreySecond;
  colorError: $colorError;
  fontFamily: $fontFamily;
  borderColor: $colorGreyFourth;
}
