@import "../../../../variables.module.scss";

.popup {
  width: 463px;
  border-radius: 18px;
  box-shadow: 0 4px 8px 4px rgba($color: #000000, $alpha: 0.04);
  background-color: $colorSecondaryWhite;
  padding: 8px 0 0;
  .header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    h3 {
      font-size: 1.188rem;
      font-weight: 500;
      width: 100%;
      display: flex;
      align-items: baseline;
      span {
        margin-left: auto;
        font-size: 0.813rem;
        color: $colorPrimarlyGreen;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          color: $colorPrimarlyBlue;
        }
      }
    }
  }
  .content {
    .notification {
      padding: 10px 50px 10px 16px;
      border-bottom: 1px solid $colorGreyFourth;
      position: relative;
      .title {
        font-size: 0.938rem;
        font-weight: 500;
      }
      .text {
        font-size: 0.813rem;
        color: $colorGreySecond;
      }
      .date {
        position: absolute;
        top: 10px;
        right: 16px;
      }
      .link {
        font-size: 0.813rem;
        color: $colorPrimarlyGreen;
        font-weight: 500;
        margin-top: 10px;
        cursor: pointer;
        &:hover {
          color: $colorPrimarlyBlue;
        }
      }
    }
  }
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 22px;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    .allNotification {
      .link {
        cursor: pointer;
        color: $colorPrimarlyGreen;
        font-size: 0.813rem;
        font-weight: 500;
        &:hover {
          color: $colorPrimarlyBlue;
        }
      }
    }
  }
}
