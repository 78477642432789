@import '../../variables.module.scss';

.formItem {
  margin-bottom: 16px;
  :global(.ant-form-item-explain) {
    font-size: 0.688rem;
    >div {
      font-size: inherit;
    }
  }

  :global(.ant-form-item-feedback-icon) {
    font-size: 0;
  }

  :global(.ant-form-item-explain) {
    padding-left: 14px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  :global(.ant-form-item-label) {
    label {
      font-size: 0.938rem;
      color: $colorSecondaryBlack;
    }
  }
}