@import "../../variables.module.scss";

.input {
  font-size: 0.938rem;
  line-height: 1.5;
  border-radius: 12px;
  border-color: $colorGreyFifth;

  padding: 15px 14px;
  background-color: $colorGreyFifth;
  transition: none;

  &:global(.ant-input-affix-wrapper-focused),
  &.isFilled {
    background-color: $colorSecondaryWhite;
    border-color: $colorPrimarlyGreen;
  }

  &:not(&.isFilled) {
    :global(.ant-input-suffix) {
      :global(.ant-form-item-feedback-icon-success) {
        display: none;
      }
    }
  }

  :global(.ant-input-prefix) {
    margin-inline-end: 14px;
  }

  :global(.ant-input) {
    font-family: $fontFamily;
    color: $colorSecondaryBlack;
    position: relative;
    top: 7px;
    background-color: inherit;
    transition: none;
  }

  :global(.ant-input-password-icon) {
    font-size: 0;
    cursor: pointer;
    margin-inline-end: 0;
  }
}

.tooltip {
  :global(.ant-tooltip-inner) {
    font-size: 0.688rem;
  }
}
