@import "./_fonts.scss";
@import "./_reset.scss";
@import "./variables.module.scss";

html {
  font-size: 16px;
}

body {
  background-image: url("/assets/images/dashboardBg.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: $colorPrimarlyGreen;
}

iframe {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
