@import "../../variables.module.scss";

.popConfirm {
  :global(.ant-popconfirm-message-icon) {
    svg {
      vertical-align: sub;
    }
  }

  :global(.ant-popconfirm-message-text) {
    margin-left: 8px;
    :global(.ant-popconfirm-title) {
      font-weight: 500 !important;
      font-size: 0.938rem !important;
      color: $colorSecondaryBlack !important;
    }

    :global(.ant-popconfirm-description) {
      font-size: 0.813rem;
      color: $colorSecondaryBlack !important;
      white-space: break-spaces;
    }
  }
}
