@import "../../../../variables.module.scss";

.modal {
  :global(.ant-modal-close) {
    top: 17px;
  }

  :global(.ant-modal-content) {
    padding-top: 0;
  }

  :global(.ant-modal-header) {
    padding-top: 16px;
    margin-bottom: 16px;
  }

  :global(.ant-modal-body) {
    padding: 8px 0;
    border-top: 1px solid $colorGreyFourth;
    border-bottom: 1px solid $colorGreyFourth;
  }

  :global(.ant-modal-footer) {
    display: flex;
    justify-content: space-around;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;

  .text {
    font-size: 0.813rem;
    color: $colorGreySecond;
    line-height: 1.56;
    text-align: center;
  }

  .personInfo {
    font-weight: 500;
  }

  :global(.ant-image) {
    margin: 16px;
  }
}
