@import "../../../../variables.module.scss";

.popup {
  width: 326px;
  background-color: $colorSecondaryWhite;
  border-radius: 18px;
  box-shadow: 0 4px 8px 4px rgba($color: #000000, $alpha: 0.04);

  .header {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 76px;
    h3 {
      font-size: 1.375rem;
      font-weight: 500;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding: 8px 16px;
    line-height: 1.5;

    .mediaItem {
      position: relative;

      height: 58px;

      border-bottom: 1px solid $colorGreyFourth;

      width: 100%;
      padding-left: 50px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      margin-bottom: 16px;
      &:last-child {
        border: 0;
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 0;
      }

      .iconWrapper {
        position: absolute;
        width: 34px;
        height: 34px;
        left: 0;
        top: 5px;
        background-color: $colorLightGreen;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title {
        font-size: 0.813rem;
        font-weight: 500;
        color: $colorGreySecond;
      }

      .value {
        font-size: 0.938rem;
        color: $colorSecondaryBlack;

        a {
          color: $colorPrimarlyGreen;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            color: $colorPrimarlyBlue;
          }
        }
      }
    }
  }
}
