@import "../../variables.module.scss";

.button {
  padding-inline-start: 24px !important;
  padding-inline-end: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $colorSecondaryWhite;
    font-size: 0.813rem;
    font-weight: 500;
  }

  &:global(.ant-btn-background-ghost) {
    span {
      color: $colorPrimarlyGreen;
    }
  }

  &[disabled] {
    background-color: $colorGreyFourth;
    border: none;
    span {
      color: $colorGreyThird;
    }
  }
  &.link {
    padding: 10px 12px !important;
    height: initial;
    display: inline-flex;
    border: none;
    span {
      color: $colorPrimarlyGreen;
      text-transform: uppercase;
    }

    &:hover {
      background-color: transparent !important;
      span {
        color: $colorPrimarlyBlue !important;
      }
    }

    &[disabled] {
      background-color: transparent;
      border: none;
      span {
        color: $colorGreyThird !important;
      }
    }
  }

  &.red {
    border-color: $colorError;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    span:first-child {
      svg {
        path {
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }

    span:last-child {
      color: $colorError;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover {
      background-color: transparent !important;
      border-color: #ff9caf !important;

      span:last-child {
        color: #ff9caf !important;
      }

      span:first-child {
        svg {
          path {
            stroke: #ff9caf !important;
          }
        }
      }
    }

    &:active {
      background-color: transparent !important;
      border-color: #d95779 !important;

      span:first-child {
        svg {
          path {
            stroke: #d95779 !important;
          }
        }
      }

      span:last-child {
        color: #d95779 !important;
      }
    }
  }
}
