.image {
  + :global(.ant-image-mask) {
    :global(.ant-image-mask-info) {
      color: inherit;
    }
  }
}

.imageWrapper {
  :global(.ant-image-preview-progress) {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    margin-bottom: 8px;

    span {
      color: inherit;
    }
  }

  :global(.ant-image-preview-operations) {
    color: inherit;

    :global(.ant-image-preview-operations-operation) {
      color: inherit;
    }
  }

  :global(.ant-image-preview-operations-operation-disabled) {
    color: rgba(255, 255, 255, 0.25) !important;
  }
}
