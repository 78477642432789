@import "../../../../variables.module.scss";

.leftSidebar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 68px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  overflow: hidden;
  background-color: $colorSecondaryWhite;
  width: 68px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 500ms;
  box-sizing: border-box;
  border-radius: 0 18px 18px 0;
  box-shadow: 1px 1px 5px -3px gray;
  z-index: 1;

  // Закомментировать след. селектор для раскрытия меню
  &.mouseEnter {
    width: 270px;

    li {
      span {
        display: inline !important;
      }

      .chevron {
        display: inline !important;
      }

      &.subMenu {
        .menuItems {
          &.shown {
            display: block !important;
          }
        }
      }
    }

    .logo {
      .icl {
        display: flex !important;
      }
    }
  }

  .logo {
    padding-left: 10px;
    padding-right: 10px;
    .wrapperIcon {
      padding-left: 6px;
      height: 100%;
      border-bottom: 1px solid $colorGreyFourth;
      display: flex;
      align-items: center;

      .icl {
        margin-left: 16px;
        display: none;
        span {
          color: $colorPrimarlyGreen;
        }
      }
    }
  }

  .menu {
    display: flex;
    justify-content: stretch;
    overflow: hidden;

    ul {
      margin-top: 13px;
      margin-left: 13px;
      margin-right: 13px;
      width: 100%;
      li {
        min-height: 36px;
        text-overflow: ellipsis;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          color: $colorSecondaryBlack;
          padding-left: 11px;
          padding-right: 11px;
          width: 100%;
          border-radius: 10px;
          box-sizing: border-box;
          height: 36px;

          &:hover {
            background-color: $colorGreyFifth;
          }

          &.active {
            background-color: rgba($color: $colorPrimarlyGreen, $alpha: 0.16);
          }

          svg {
            flex-shrink: 0;
          }
        }

        span {
          margin-left: 12px;
          display: none;
          white-space: nowrap;
          font-size: 0.938rem;
          font-weight: 500;

          &.greenSpan {
            color: $colorPrimarlyGreen;
          }
        }

        .chevron {
          display: none;
        }

        &.subMenu {
          padding-left: 11px;
          padding-right: 11px;
          position: relative;

          .toggleMenuLabel {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 36px;

            > svg {
              flex-shrink: 0;
            }

            .chevron {
              margin-left: auto;
              transition: transform 0.375s;
            }

            &.menuIsShown {
              .chevron {
                transform: scale(1, -1);
              }
            }

            &.subItemIsSelected {
              svg {
                &:first-child {
                  filter: brightness(0.5);
                }
              }

              > span {
                filter: brightness(0.5);
              }
            }
          }

          .menuItems {
            display: none;
            margin-top: 8px;
            li {
              margin-bottom: 8px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
